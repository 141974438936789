<template>
  <div class="mt-4">
    <v-row>
      <v-col md="3"
        ><competence-sidebar
          :active-competence-id="compId"
          route-name="competence_sync"
        ></competence-sidebar>
      </v-col>
      <v-col md="9">
        <div class="mb-4">
          <h3>Задачи на пересчет баллов</h3>
          <p>
            Здесь отображаются задачи на пересчет баллов по конкурсу талант для
            компетенции.
            <strong>{{ competence ? competence.name : compId }}</strong
            >.
          </p>
          <v-btn color="primary" :loading="createPending" @click="createTask"
            >Запустить пересчет</v-btn
          >
        </div>
        <div class="my-3">
          Найдено задач: {{ total }}.
          <a href="#" @click.prevent="getList(1)">Обновить список</a>
        </div>
        <div style="min-height: 4px">
          <v-progress-linear
            indeterminate
            color="yellow darken-2"
            :active="pending"
          ></v-progress-linear>
        </div>
        <v-simple-table class="mb-3">
          <template #default>
            <thead>
              <tr>
                <th class="text-left">№</th>
                <th class="text-left">Статус</th>
                <th class="text-left">Дата создания</th>
                <th class="text-left">Автор</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="task in taskList" :key="task.id">
                <td>
                  {{ task.id }}
                </td>
                <td>
                  <v-icon v-if="task.statusIcon" color="grey lighten-1" left>{{
                    task.statusIcon
                  }}</v-icon
                  >{{ task.statusText || task.status }}
                </td>
                <td>{{ task.created_at }}</td>
                <td>{{ task.author }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <app-pager
          :value="page"
          :total="total"
          :limit="limit"
          class="pager"
          @input="getList"
        ></app-pager>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { apiClient } from "@/api";
import { getStatusData } from "@/services/task";
import dayjs from "@/plugins/dayjs";
import CompetenceSidebar from "@/components/CompetenceSidebar";

export default {
  name: "Sync",
  components: {
    CompetenceSidebar,
  },
  data() {
    return {
      pending: false,
      page: 1,
      limit: 10,
      total: 0,
      error: "",
      tasks: [],
      createPending: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    compId() {
      return +this.$route.params.id;
    },
    competence() {
      return this.$store.getters["competenceById"](this.compId);
    },
    taskList() {
      return this.tasks.map((task) => {
        const status = getStatusData(task.status);
        let author;
        if (task.author_id === this.user?.id) {
          author = "Вы";
        } else {
          author = task.author_id;
        }
        return {
          ...task,
          ...(status && { ...status }),
          created_at: dayjs(task.created_at).format("LLL"),
          author,
        };
      });
    },
  },
  watch: {
    compId() {
      clearTimeout(this._polingInterval);
      this.getList(1);
    },
  },
  created() {
    this._polingInterval = null;
    this._watchTask = null;
    this.getList();
  },
  beforeDestroy() {
    clearTimeout(this._polingInterval);
  },
  methods: {
    async getList(page = 1) {
      if (this.pending) return;
      this.pending = true;
      this.error = "";
      try {
        const { data } = await apiClient({
          url: `/competences/${this.compId}/tasks`,
          params: {
            limit: this.limit,
            offset: this.limit * (page - 1),
          },
        });
        this.tasks = data.results;
        if ("count" in data) {
          this.total = data.count;
        }
        this.page = page;
      } catch (error) {
        this.error = error?.message || "Непредвиденная ошибка";
      }
      this.pending = false;
    },
    async createTask() {
      const confirm = await this.$root.$confirm(
        "Пересчитать баллы",
        `Вы уверены что хотите пересчитать баллы за компетенцию ${this.competence?.name}?`,
        {
          confirmText: "Да, пересчитать",
          rejectText: "Нет, отмена",
          confirmColor: "primary",
        }
      );
      if (!confirm) return;
      this.createPending = true;
      try {
        const { data } = await apiClient({
          method: "POST",
          url: `/competences/${this.compId}/tasks/`,
        });
        this.$toast(
          `Заддача на пересчет баллов №${data.id} добавлена в очередь`,
          {
            type: "success",
          }
        );

        if (this.page === 1 && this.total < this.limit) {
          this.tasks.unshift(data);
          if (this.total >= this.limit) {
            this.tasks.splice(this.limit - 1, 1);
          }
          this.total += 1;
        } else {
          this.getList(1);
        }
      } catch (error) {
        this.$toast(`Не удалось создать задачу. ${error.message}`, {
          type: "error",
        });
      }
      this.createPending = false;
    },
  },
};
</script>

<style scoped>
.pager >>> .v-pagination {
  justify-content: flex-start !important;
}
</style>
